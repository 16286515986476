.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.fonts {
  font-family: flegrei, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-family: pantelleria, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-family: filicudi-striped, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-family: filicudi-solid, sans-serif;
  font-weight: 400;
  font-style: normal;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.VibeButton, .VibeButton svg {
  border: 1px solid;
  animation: 15s Button-color-cycle ease-in-out infinite;
}

.vibes-header-text {
  font-family: flegrei, sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.vibes-location-wall {
  height: calc(100vh - 100px);
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;;
}

.vibes-container, .vibes-container div{
  overflow: visible;
}

.vibes-icon-button svg, .vibes-icon-button path {
  pointer-events: none;
}

.vibe-icon-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.vibes-picker {
  display: flex;
  flex-direction: column;
}

.vibe-button-add {
  padding: 1em !important;
  background: rgb(240,148,54);
  /* background: radial-gradient(circle, rgba(240,148,54,1) 0%, rgba(247,210,55,1) 100%); */
}

.vibes-location {
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.vibe-button-location {
  padding: 1em !important;
  background: #efefef !important;
}

#vibes-dialog-title {
  margin-bottom:0;
  padding-bottom:0;
}

.vibes-header-text-container {
  text-align:center;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;;
}

.vibes-icons {
  padding: 1em;
  display:flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
}

.vibe-custom {
  padding: 2em;
  display:flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1em;
  border: 1px solid #efefef;
  background: #f9f9f9;
}

.vibe-custom-text {
  margin: 1em;
}

.vibe-custom-picker {
  margin-bottom: 1em !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes Button-color-cycle {
  0% {
    border: 2px solid rgba(232,69,160,1) ;

  }
  8% {
    border: 2px solid  rgba(228,147,77,1);

  }
  16% {
    border: 2px solid  rgba(240,205,87,1);

  }
  24% {
    border: 2px solid  rgba(77,217,120,1);

  }
  32% {
    border: 2px solid  rgba(54,167,246,1);
  }
  40% {
    border: 2px solid  rgba(255,255,255,1);
  }
  80% {
    border: 2px solid  rgba(255,255,255,1);
  }
  100% {
    border: 2px solid rgba(232,69,160,1) ;
  }
}


@keyframes Svg-color-cycle {
  0% {
    fill:red ;

  }
  15% {
    fill: orange;

  }
  30% {
    fill: yellow;

  }
  45% {
    fill: green;

  }
  60% {
    fill: blue;

  }
  75% {
    fill: indigo;

  }
  90% {
    fill: purple;

  }
  100% {
    fill: grey;

  }
}







.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}




.vibe {
  width:50px;
  height:50px;
  opacity: .25;
  border-radius: 10px;
  transform:translate3d(-25px,-25px,0);
}

.vibe-recent {
    width: 100px;
    height: 100px;
}

.VibeIcon img {
    object-fit: cover;
    width: 100%;
}

.VibeIcon > svg{
  transform-origin: center center;
  animation: Svg-color-cycle 5s ease infinite;
}

@keyframes pulsate-vibe-0 {
  0% {
    transform: translate3d(-25px,-25px,0);
  }
  50% {
    transform: translate3d(-30px,-20px,0);
  }
  100% {
    transform: translate3d(-25px,-25px,0);
  }
}

@keyframes pulsate-vibe-1 {
  0% {
    transform: translate3d(-25px,-25px,0);
  }
  50% {
    transform: translate3d(-20px,-20px,0);
  }
  100% {
    transform: translate3d(-25px,-25px,0);
  }
}

@keyframes pulsate-vibe-2 {
  0% {
    transform: translate3d(-25px,-25px,0);
  }
  50% {
    transform: translate3d(-30px,-30px,0);
  }
  100% {
    transform: translate3d(-25px,-25px,0);
  }
}

@keyframes pulsate-vibe-3 {
  0% {
    transform: translate3d(-25px,-25px,0);
  }
  50% {
    transform: translate3d(-20px,-30px,0);
  }
  100% {
    transform: translate3d(-25px,-25px,0);
  }
}

@keyframes pulsate-vibe-4 {
  0% {
    transform: translate3d(-25px,-25px,0);
  }
  50% {
    transform: translate3d(-20px,-20px,0);
  }
  100% {
    transform: translate3d(-25px,-25px,0);
  }
}

@keyframes pulsate-vibe-5 {
  0% {
    transform: translate3d(-25px,-25px,0);
  }
  50% {
    transform: translate3d(-20px,-30px,0);
  }
  100% {
    transform: translate3d(-25px,-25px,0);
  }
}

@keyframes pulsate-vibe-scale {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}


.vibe-5 {
  background: radial-gradient( rgba(232,69,160,1) 0%, rgba(232,69,160,0) 70%, rgba(232,69,160,0) 100%);
  /* animation: pulsate-vibe-scale 5s ease infinite; */
}
.vibe-4 {
  background: radial-gradient( rgba(228,147,77,1) 0%, rgba(228,147,77,0) 70%, rgba(228,147,77,0) 100%);
  /* animation: pulsate-vibe-scale 4s ease infinite; */
}
.vibe-3 {
  background: radial-gradient( rgba(240,205,87,1) 0%, rgba(240,205,87,0) 70%, rgba(240,205,87,0) 100%);
  /* animation: pulsate-vibe-scale 3s ease infinite; */
}
.vibe-2 {
  background: radial-gradient( rgba(77,217,120,1) 0%, rgba(77,217,120,0) 70%, rgba(77,217,120,0) 100%);
  /* animation: pulsate-vibe-scale 2s ease infinite; */
}
.vibe-1 {
  background: radial-gradient( rgba(54,167,246,1) 0%, rgba(54,167,246,0) 70%, rgba(54,167,246,0) 100%);
  /* animation: pulsate-vibe-scale 1s ease infinite; */
}
.vibe-0 {
  background: radial-gradient( rgba(210,210,210,1) 0%, rgba(210,210,210,0) 70%, rgba(210,210,210,0) 100%);
  /* animation: pulsate-vibe-scale 6s ease infinite; */
}
